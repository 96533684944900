import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './routers/appRouter';
import ParticlesBg from 'particles-bg';
import ReactGA from 'react-ga4';

const App = () => {

    useEffect(() => {
        ReactGA.initialize('G-N2DY5QGC50');
        ReactGA.send('pageview');
    }, []);

    const backgroundWrapperStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -1,
        overflow: 'hidden',
    };

    const transparentImageStyle = {
        width: '100%',
        height: '100%',
        opacity: 0.5,
        objectFit: 'cover',
    };

    const appStyle = {
        position: 'relative',
        zIndex: 1,
        minHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        cursor: 'url("custom-cursor.png"), auto',
    };

    return (
        <Router>
            <div style={appStyle} className='App'>
                {/* Background Wrapper */}
                <div style={backgroundWrapperStyle}>
                    <img src="background.jpg" alt="Background" style={transparentImageStyle} />
                    <ParticlesBg
                        type="cobweb"
                        bg={{
                            position: 'absolute',
                            zIndex: -1,
                            opacity: 0.5,
                        }}
                    />
                </div>

                {/* Main Application Router */}
                <AppRouter />
            </div>
        </Router>
    );
};

export default App;
