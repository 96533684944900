// src/components/PricingFeatures.js
import React from 'react';
import content from '../data/content.json';

const PricingFeatures = () => (
  <section className="pricing-features-section">
    <h2>Solutions</h2>
    <div className="pricing-grid">
      {content.pricing.map((item, index) => (
        <div className="pricing-card" key={index}>
          <h3>{item.name}</h3>
          <p className="price">{item.price}</p>
          <ul className="features-list">
            {item.features.map((feature, idx) => (
              <li key={idx}>
                <i className={feature.icon}></i> {feature.text}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </section>
);

export default PricingFeatures;
