import ReactGA from 'react-ga4';

const trackButtonClick = (buttonName) => {
  ReactGA.event({
    category: 'User',
    action: 'Clicked Button',
    label: buttonName
  });
};

const MyButton = () => {
  return (
    <button onClick={() => trackButtonClick('My Custom Button')}>
      Click Me
    </button>
  );
};

export default MyButton;
