import React from 'react';
import content from '../data/content.json';
//import '../styles/blog.css';

const BlogSection = () => (
  <section className="blog-section">
    <h2>{content.blog.title}</h2>
    <p>{content.blog.description}</p>
    <div className="blog-posts">
      {content.blog.posts.map((post, index) => (
        <div key={index} className="blog-post">
          <h3><a href={post.link}>{post.title}</a></h3>
          <p>{post.date}</p>
          <p>{post.summary}</p>
        </div>
      ))}
    </div>
  </section>
);

export default BlogSection;
