import React from 'react';
import content from '../data/content.json';
//import '../styles/policy.css';

const PolicySection = () => (
  <section className="policy-section">
    <h2>{content.policy.title}</h2>
    <p>{content.policy.description}</p>
    <div className="policy-contents">
      {content.policy.sections.map((section, index) => (
        <div key={index} className="policy-section-item">
          <h3>{section.heading}</h3>
          <p>{section.content}</p>
        </div>
      ))}
    </div>
  </section>
);

export default PolicySection;
