// src/components/Brochure.jsx
import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import brochureData from '../data/brochure.json';
import trackButtonClick from '../components/fn/trackClicks'
import { Link } from 'react-router-dom';

const Brochure = () => {
  const generateBrochurePDF = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
    });

    // Margins
    const margins = { top: 10, left: 10, right: 10 };

    // 1. Welcome Title and Note about Website Development
    doc.setFont("helvetica", "bold");
    doc.setFontSize(24);
    doc.text(brochureData.header.title, margins.left, 30); // Welcome title

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    let y = 45;
    brochureData.header.description.forEach((line) => {
      doc.text(line, margins.left, y);
      y += 10;
    });

    // Add spacing for the next section
    y += 15;

    // 2. Pricing Sheet in Table Format
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text('Website Development Details', margins.left, y); // Section title

    y += 10;

    // Prepare data for the table
    const pricingData = brochureData.pricing.map(item => {
      const featuresText = item.features.map(feature => feature.text).join('\n'); // Join feature texts
      return [
        { content: item.title, styles: { halign: 'center', fillColor: [211, 211, 211] } },
        item.price,
        featuresText
      ];
    });

    // Add the table
    doc.autoTable({
      head: [['Service', 'Price', 'Features']],
      body: pricingData,
      startY: y,
      margin: { top: 10, left: 10, right: 10 },
      theme: 'grid',
      styles: {
        fontSize: 8,
        cellPadding: 3,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
      },
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 30 },
        2: { cellWidth: 70 },
      },
      headStyles: {
        fillColor: [0, 102, 204],
        textColor: [255, 255, 255],
      },
    });

    // 3. About Developer Details and Contact Info
    const finalY = doc.lastAutoTable.finalY + 20; // Get the final Y position after the table
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text(brochureData.footer.about.title, margins.left, finalY);

    let contactY = finalY + 10;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    brochureData.footer.about.description.forEach((line) => {
      doc.text(line, margins.left, contactY);
      contactY += 8; // Reduced line height
    });

    contactY += 10;
    doc.setFont("helvetica", "bold");
    doc.text('Contact Information', margins.left, contactY);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10); // Restore to a slightly larger font for contact info
    doc.text(`Phone: ${brochureData.footer.contact.phone}`, margins.left, contactY + 10);
    doc.text(`Email: ${brochureData.footer.contact.email}`, margins.left, contactY + 20);

    // Save the PDF
    doc.save('brochure.pdf');
  };

  return (
    <Link to="/brochure" className="link" onClick={(e) => {
      e.preventDefault(); // Prevent default link behavior
      trackButtonClick(); // Track button click
      generateBrochurePDF(); // Generate the brochure PDF
    }}>
      <button className="download-button">
        Brochure
      </button>
    </Link>
  );
};

export default Brochure;
