// src/routers/appRouter.js
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LandingPage from '../components/landingPage';
import ReactGA from 'react-ga4'; // For Google Analytics 4
import FooterSection from '../components/footer';

const AppRouter = ({ onContactClick }) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname }); // Track page views
    
    // Open modal based on the URL path
    if (location.pathname === '/services') {
      setModalContent('services');
      setIsModalOpen(true);
    } else if (location.pathname === '/blog') {
      setModalContent('blog');
      setIsModalOpen(true);
    } else if (location.pathname === '/policy') {
      setModalContent('policy');
      setIsModalOpen(true);
    }
  }, [location.pathname]);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    navigate('/'); // Navigate back to home after closing the modal
  };

  return (
    <Routes>
      {/* Main Landing Page */}
      <Route path="/" element={<LandingPage onContactClick={onContactClick} />} />
      {/* Handle other routes as modals */}
      <Route path="*" element={<FooterSection isModalOpen={isModalOpen} modalContent={modalContent} onCloseModal={closeModal} />} />
      <Route path="/contact" element={<LandingPage />} />
        <Route path="/brochure" element={<LandingPage />} />
    </Routes>
  );
};

export default AppRouter;
