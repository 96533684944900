// src/components/Header.jsx
import React from 'react';
import content from '../data/content.json';
import '../styles/header.css';
import trackButtonClick from '../components/fn/trackClicks';
import { Link } from 'react-router-dom';
import Brochure from './brochure';
import { Helmet } from 'react-helmet'; 

const Header = ({ onContactClick, onBrochureClick }) => {
  return (
    <section className="header-section">
            <Helmet>
        <title>{content.header.title}</title>
        <meta name="description" content={content.header.metaDescription} />
      </Helmet>
      <h1>{content.header.title}</h1>
      <p>
        {content.header.description.map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </p>
      <div className="button-container">
        <Link
          to="/contact" 
          className="contact-button" 
          onClick={() => {
            trackButtonClick(); // Track button click
            onContactClick();   // Trigger contact popup
          }}
        >
          Contact Form
        </Link>
    <Brochure />

      </div>
    </section>
  );
};

export default Header;
