import React from 'react';
import '../../styles/modal.css'; // Make sure to create this CSS file for styling
import ReactGA from 'react-ga4'

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  ReactGA.event({
    category: 'User',
    action: 'Opened Modal',
    label: 'Service Modal'
  });
 // setIsModalOpen(true);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
