import React from 'react';
import content from '../data/content.json';
//import '../styles/services.css';

const ServicesSection = () => (
  <section className="services-section">
    <h2>{content.services.title}</h2>
    <p>{content.services.description}</p>

    {/* Add project pitch here */}
    <div className="project-pitch">
      <h3>Project Pitch</h3>
      <p>{content.projectPitch}</p> {/* Ensure this is correctly pulling from your JSON */}
    </div>

    <div className="service-list">
      {content.services.serviceList.map((service, index) => (
        <div key={index} className="service-item">
          <h3>{service.title}</h3>
          <p>{service.description}</p>
          <p>Price: {service.price}</p>
        </div>
      ))}
    </div>
  </section>
);

export default ServicesSection;
