// src/components/LandingPage.js
import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../styles/landing.css";
import HeaderSection from "./header";
import PricingFeatures from "./pricingFeatures";
import ContactPopup from "./fn/popUp";
import FooterSection from "./footer";

const LandingPage = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContactClick = () => {
    setPopupVisible(true);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      setFeedbackMessage("Message sent successfully!");
      setPopupVisible(false);
    } catch (error) {
      setFeedbackMessage("Failed to send message, please try again.");
      console.error("EmailJS Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setPopupVisible(false);
  };

  return (
    <div className="landing-page">
      <HeaderSection onContactClick={handleContactClick} />
      <div className="note-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="note-icon blinking-icon" // Add this class for blinking effect
          viewBox="0 0 24 24"
        >
          <path d="M12 0a12 12 0 0 0 0 24A12 12 0 0 0 12 0zm0 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM11 6h2v2h-2V6zm0 4h2v8h-2v-8z" />
        </svg>
        <span className="note-text">
          Note: A 25% service charge applies to third-party payments, which can be avoided by handling payments directly. For details, visit our site footer. We serve only MSME clients.
        </span>
      </div>
      <PricingFeatures />
      <div className="seo-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="seo-icon"
          viewBox="0 0 24 24"
        >
          <path d="M11 2a9 9 0 1 0 8.468 12.648l4.57 4.57a1 1 0 0 0 1.414-1.414l-4.57-4.57A9 9 0 0 0 11 2zm0 2a7 7 0 1 1 0 14 7 7 0 0 1 0-14zM13 11h-2v2h2v-2z" />
        </svg>
        <p>
          Digital Marketing SEO: Unlock Organic & Paid Traffic — Get Your Personalized Quote Today!
        </p>
      </div>

      <ContactPopup
        isVisible={isPopupVisible}
        onSend={handleSend}
        onCancel={handleCancel}
        loading={loading}
        formData={formData}
        onInputChange={handleInputChange}
        feedbackMessage={feedbackMessage}
      />

      <FooterSection />
    </div>
  );
};

export default LandingPage;
