// src/components/ContactPopup.js
import React from 'react';
import '../../styles/popUp.css';


const ContactPopup = ({ isVisible, onSend, onCancel, loading, formData, onInputChange, feedbackMessage }) => {
  if (!isVisible) return null; // Early return for better readability

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Contact Us</h2>
        <form onSubmit={onSend}>
          <fieldset>
            <div>
              <label htmlFor="name">Name:</label>
              <input 
                type="text" 
                id="name" 
                name="name" 
                required 
                value={formData.name} 
                onChange={onInputChange} 
                aria-describedby="nameHelp" // ARIA for better accessibility
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                required 
                value={formData.email} 
                onChange={onInputChange} 
                aria-describedby="emailHelp"
              />
            </div>
            <div>
              <label htmlFor="phone">Phone Number:</label>
              <input 
                type="tel" 
                id="phone" 
                name="phone" 
                required 
                pattern="[0-9]{10}" 
                title="Enter a valid 10-digit phone number" 
                value={formData.phone} 
                onChange={onInputChange} 
                aria-describedby="phoneHelp"
              />
            </div>
            <div>
              <label htmlFor="message">Message:</label>
              <textarea 
                id="message" 
                name="message" 
                required 
                value={formData.message} 
                onChange={onInputChange}
                aria-describedby="messageHelp"
              />
            </div>
          </fieldset>
          <div className="popup-buttons">
            <button type="submit" disabled={loading}>
              {loading ? "Sending..." : "Send"}
            </button>
            <button type="button" onClick={onCancel}>Cancel</button>
          </div>
          {feedbackMessage && <p className="feedback-message">{feedbackMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default ContactPopup;
