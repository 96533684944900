import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Modal from '../components/fn/modal';
import ServicesSection from '../components/services';
import BlogSection from '../components/blog';
import PolicySection from '../components/policy';
import content from '../data/content.json';
import '../styles/footer.css';

const FooterSection = ({ isModalOpen, modalContent, onCloseModal }) => {
  
  // Function to render modal content based on the type
  const renderModalContent = () => {
    switch (modalContent) {
      case 'services':
        return <ServicesSection />;
      case 'blog':
        return <BlogSection />;
      case 'policy':
        return <PolicySection />;
      default:
        return null;
    }
  };

  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="footer-left">
          <h3>{content.footer.about.title}</h3>
          {content.footer.about.description.map((desc, idx) => (
            <p key={idx}>{desc}</p>
          ))}
        </div>

        <div className="footer-center">
          <p>&copy; 2024 CraftMan.io All rights reserved!</p>
          <p className="client-details">
            Clients:&nbsp;
            <a href="https://ahobilamhotels.com" target="_blank" rel="noopener noreferrer">
              AhobilamHotels.com
            </a>
            &nbsp;|&nbsp;
            <a href="https://FireSafeguardSystems.com" target="_blank" rel="noopener noreferrer">
              FireSafeguardSystems.com
            </a>
            &nbsp;|&nbsp;
            <a href="https://ahobilamguide.com" target="_blank" rel="noopener noreferrer">
            AhobilamGuide.com
            </a>
          </p>
        </div>

        <div className="footer-right">
          <h3>Contact</h3>
          <p>Phone: <a href={`tel:${content.footer.contact.phone}`}>{content.footer.contact.phone}</a></p>
          <p>Email: <a href={`mailto:${content.footer.contact.email}`}>{content.footer.contact.email}</a></p>
          <div className="social-media">
            <a href={content.footer.contact.social.twitter} target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-twitter"></i>
            </a>
            <a href={content.footer.contact.social.linkedin} target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Icons for additional pages */}
      <div className="footer-icons">
        <Link to="/services" className="footer-icon" aria-label="Services">
          <i className="fas fa-concierge-bell"></i> {/* Icon for services */}
        </Link>
        <Link to="/blog" className="footer-icon" aria-label="Blog">
          <i className="fas fa-pencil-alt"></i> {/* Icon for blog */}
        </Link>
        <Link to="/policy" className="footer-icon" aria-label="Policy">
          <i className="fas fa-shield-alt"></i> {/* Icon for policy */}
        </Link>
      </div>

      {/* Modal for displaying the content */}
      <Modal isOpen={isModalOpen} onClose={onCloseModal}>
        {renderModalContent()}
      </Modal>
    </footer>
  );
};

export default FooterSection;
